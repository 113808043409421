<template>
  <div class="contents">
    <div class="card shadow">
      <div class="card-body mt10">

        <!-- 검색 -->
        <div class="credit-table-block pl20 pt20 pb20 mb20">
          <label  for="" class="tx_rt ml30 mr10 bold"><i class="fas fa-caret-right fs14"></i> 개인/법인</label>
          <base-select-box
              :class="selectBoxClass2"
              :id="'selectBoxCorp'"
              :items="corpTypeList"
              @toParent:selectValue="val => getCardCompanyList(val)"
              @toParent:setValue="val => corpGbn = val"/>

          <label  for="" class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i> 카드사</label>
          <base-select-box
              :class="selectBoxClass1"
              :id="'selectBoxCard'"
              :items="cardCompanyList"
              v-model="cardCompanyList"
              @toParent:selectValue="val => selectCardCompany(val)"
              @toParent:setValue="val => cardGbn = val"/>

          <button type="button" @click="authInquriy" class="btn btn-darkgrey ml20 mr20 w120">조회</button>
        </div>
        <div class="mb10">
          <p class="text-red bold">신용카드 등록 후 최초 1회 스크래핑 수동실행</p>
          <p class="text-red">(스크랩핑 관리 - 스크랩핑 수동실행 - 신용카드 - 카드승인내역 - 조회 - 수동실행 클릭)
          등록일 이후 이용내역은 자동수집 되나, 등록일 이전 이용내역은 수동수집이 필요합니다</p>
          <p class="text-red">인터넷 환경이 좋지 않은 상황이거나 카드사 서버가 불안정한 경우 비밀번호 검증 과정에 실패가 발생 할 수 있으니 참고하시기 바랍니다.</p>
        </div>
        <!-- 카드사 인증서명 테이블 -->
        <div class="btnwrap-left">
          <button type="button" @click="openLoginIdPwdPopup" class="btn btn-primary pr20 pl20">인증정보 추가</button>
        </div>
        <div class="btnwrap-right">
          <button type="button" @click="openSimpleCardRegPopup" class="btn btn-info pr20 pl20 mr5"><i class="fas fa-plus-square txt-white pr5"></i> 간편 카드 등록</button>
          <button type="button" @click="deleteAuthList" class="btn btn-black pr20 pl20">일괄삭제</button>
        </div>

        <table class="table01">
          <caption>신용카드 표</caption>
          <colgroup>
            <col v-for="(item, index) in tableData_attr.heads" :key="index" :style="item.style">
          </colgroup>
          <thead>
          <tr>
            <th v-for="(item, index) in tableData_attr.heads" :key="index" class="tx_cnt" v-html="item.col" >{{item.col}}</th>
          </tr>
          </thead>
          <tbody v-if="tableData_attr.items.length > 0">
          <tr v-for="(item, index) in tableData_attr.items" :key="index">
            <td class="tx_cnt">
              <label><input type="checkbox" :value="item.idx" v-model="delRequestAuthList"></label>
            </td>
            <td class="tx_cnt">{{item.scrapSiteName}}</td>
            <td class="tx_cnt">{{item.corpGbn}}</td>
            <td class="tx_cnt">
              <span :class="item.authType =='ID' ? 'label-blue':'label-green' ">{{item.authType == "ID" ? "ID/PW" : "인증서"}}</span><!-- 인증서 -->
            </td>
            <td class="tx_cnt">{{item.authId}}</td>
            <td class="tx_cnt">{{item.regDt}}</td>
            <td class="tx_cnt txt-primary" v-if="item.status == 'VALID'">
              <!-- 성공 -->
              정상
            </td>
            <td class="tx_cnt" v-else>
              <!-- 실패 -->
              <div class="tooltip">
                <span class="tooltip-cursor txt-danger">오류</span>
                <span class="tooltiptext form-group has-error tx_cnt">
										<i class="fa fa-times-circle-o has-error"/>{{item.statusDesc}}
									</span>
              </div>
            </td>
            <td class="tx_cnt">{{item.financeCnt}}</td>
            <td class="tx_cnt">
              <button v-if="item.authType == 'CERT'" type="button" @click="openLoginDetailsInfoPopup_cert(item.idx)" class="btn btn-block btn-default btn-sm mr5 w100">인증정보 관리</button>
              <button v-if="item.authType == 'ID'" type="button" @click="openLoginDetailsInfoPopup_idpwd(item.idx)" class="btn btn-block btn-default btn-sm mr5 w100">인증정보 관리</button>
              <button type="button" @click="openCreditCardMngPopup(item)" class="btn btn-block btn-default btn-sm">카드관리</button>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- //카드사 인증서명 테이블 -->
        <base-pagination
            :prefix="'/auth/card'"
            :pTotalListItemCount="authCount"
            :pageInit="pageInit"
            @toParent:changePage="(val) => pageIndex = val"
        />
      </div>
    </div>

    <!-- Modal Popup - 인증정보 추가 -->
    <base-auth-reg-popup
        :popupTitle=authRegTitle
        :visible="visible1"
        :financeType="'CARD'"
        @toParent:reInqury="authInquriy()"
        @toParent:visible="val => visible1=val"
    >
    </base-auth-reg-popup>
    <!-- //Modal Popup - 인증정보 추가 -->

    <!-- Modal Popup - 신용카드 등록/관리 선택 -->
    <div class="w3-container">
      <base-credit-card-reg-popup
          :visible="visible2"
          :idx="cardInfoTable_attrs.idx"
          :cardNm="cardInfoTable_attrs.cardNm"
          :authType="cardInfoTable_attrs.authType"
          :authNm="cardInfoTable_attrs.authNm"
          :bizKind="cardInfoTable_attrs.bizKind"
          :heads="cardInfoTable_attrs.heads"
          :bodys="cardInfoTable_attrs.bodys"
          @toParent:visible="val => closeCreditCardRegPopup(val)"/>
    </div>
    <!-- //Modal Popup - 신용카드 등록/관리 선택 -->

    <!-- Modal Popup - 인증서 상세정보 -->
    <div class="w3-container">
      <base-login-details-info-popup
          :visible="visible3"
          :scrapSiteType="loginDetailsInfo_cert_attrs.scrapSiteType"
          :scrapSiteName="loginDetailsInfo_cert_attrs.scrapSiteName"
          :authType="loginDetailsInfo_cert_attrs.authType"
          :bizKind="loginDetailsInfo_cert_attrs.bizKind"
          :gubun="loginDetailsInfo_cert_attrs.gubun"
          :popupTitle="loginDetailsInfo_cert_attrs.popupTitle"
          :caption="loginDetailsInfo_cert_attrs.caption"
          :heads="loginDetailsInfo_cert_attrs.heads"
          :bodys="loginDetailsInfo_cert_attrs.bodys"
          :idx="loginDetailsInfo_cert_attrs.idx"
          :updateOptions="executeUpdateOptions"
          @toParent:visible="val => visible3=val"
          @toParent:executeCertUpdate="idx => executeCertUpdate(idx)">

        <div class="btnwrap clear pt10" slot= "slot_btns">
          <!-- <button onclick="" type="button" class="btn btn-block btn-primary w115" >저장</button> -->
          <button @click="certUpdate()" type="button" class="btn btn-block btn-danger mr10 w120">인증서 교체</button>
        </div>
      </base-login-details-info-popup>
    </div>
    <!-- //Modal Popup - 인증서 상세정보 -->

    <!-- Modal Popup - ID/PW 상세 정보 -->
    <div class="w3-container">
      <base-login-details-info-popup
          :visible="visible4"
          :scrapSiteType="loginDetailsInfo_idpwd_attrs.scrapSiteType"
          :scrapSiteName="loginDetailsInfo_idpwd_attrs.scrapSiteName"
          :authType="loginDetailsInfo_idpwd_attrs.authType"
          :bizKind="loginDetailsInfo_idpwd_attrs.bizKind"
          :gubun="loginDetailsInfo_idpwd_attrs.gubun"
          :popupTitle="loginDetailsInfo_idpwd_attrs.popupTitle"
          :caption="loginDetailsInfo_idpwd_attrs.caption"
          :heads="loginDetailsInfo_idpwd_attrs.heads"
          :bodys="loginDetailsInfo_idpwd_attrs.bodys"
          :idx="loginDetailsInfo_idpwd_attrs.idx"
          :changeYn="pwdChangeYn"
          :updateOptions="executeUpdateOptions"
          @toParent:visible="(val) => {visible4=val; pwdChangeYn = val}"
          @toParnet:executePwdUpdate="(idx, pwd) => executePwdUpdate(idx, pwd)">

        <div class="btnwrap clear pt10" slot= "slot_btns">
          <button v-if="pwdChangeYn" @click="pwdUpdate()" type="button" class="btn btn-block btn-primary w115">저장</button>
          <button v-if="!pwdChangeYn" @click="pwdChange()" type="button" class="btn btn-block btn-danger mr10 w120">패스워드 변경</button>
        </div>
      </base-login-details-info-popup>
    </div>
    <!-- //Modal Popup - ID/PW 상세 정보 -->

    <!-- Modal Popup - 간편 카드 등록 -->
    <div class="w3-container">
      <base-simple-reg-popup
          :popupTitle="simpleCardTitle"
          :visible="visible5"
          :init="cardRegInit"
          :financeType="'CARD'"
          @toParent:visible="val => closeSimpleCardRegPopup(val)" />
    </div>
    <!-- //Modal Popup - 간편 카드 등록 -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
	name: 'PageAuthCard',

	data: () => ({
		prefix:"api/auth/card",

		// init Constant
		cardCompanyList : [],
		preselectValue : "개인",

		// Variable
		visible1: false,
		visible2: false,
		visible3: false,
		visible4: false,
		visible5: false,
		selectBoxClass1: 'form-control-inline w120',
		selectBoxClass2: 'form-control-inline w80',
		simpleCardTitle: '간편 카드 등록',
		authRegTitle: '인증정보 추가',

		corpGbn : "",
		cardGbn : "",

		cardRegInit: false,
		delRequestAuthList : [],

		// Components attrs
		//인증서 인증정보 상세팝업
		loginDetailsInfo_cert_attrs: {
			scrapSiteType: "",
			scrapSiteName: "",
			authType: "",
			bizKind: "",
			gubun: 'cert',
			popupTitle: '인증서 상세 정보',
			caption: '인증서 상세 정보 표',
			idx:"",
			heads: [
				{col: '', style: 'width:150px',},
				{col: '', style: '',},
			],
			bodys: [],
		},
		//idpw 인증정보 상세팝업
		loginDetailsInfo_idpwd_attrs: {
			scrapSiteType: "",
			scrapSiteName: "",
			authType: "",
			bizKind: "",
			gubun: 'idpwd',
			popupTitle: 'ID/PW 상세 정보',
			caption: 'ID/PW 상세 정보 표',
			idx:"",
			heads: [
				{col: '', style: 'width:150px',},
				{col: '', style: '',},
			],
			bodys: [],
		},
		pwdChangeYn: false,
		executeUpdateOptions:{},

		//카드관리 팝업
		cardInfoTable_attrs:{
			idx: "",
			cardNm : "",
			authType : "", //CERT 혹은 ID
			bizKind: "",
			authNm : "", //인증서명 혹은 웹 Id
			heads:[
				{col: '선택', style: 'width:50px'},
				{col: '카드번호', style: ''},
				{col: '별칭', style: 'width:100px'},
				{col: '상태', style: 'width:90px'},
				{col: '관리', style: 'width:80px'},
			],
			bodys:[
				{
					idx: "6",
					cardNo:"1234567890009876",
					cardAlias:"별칭",
					status:"INVALID",
					statusDesc:"오류메세지를 여기에 쓴다.",
				}
			],
		},
		tableData_attr:{
			heads:[
				{col: '선택', style:'width:50px;'},
				{col: '카드사', style:'width:95px;'},
				{col: '구분', style:'width:60px;'},
				{col: '인증수단', style:'width:80px;'},
				{col: '인증ID', style:''},
				{col: '등록일자', style:'width:100px;'},
				{col: '상태', style:'width:60px;'},
				{col: '등록카드', style:'width:80px;'},
				{col: '관리', style:'width:210px;'},
			],
			items:[],
		},

		//pagination
		authCount: 0,
		pageIndex: 10,
		pageLimit: 10,
		pageInit: false,

	}),
	watch:{
		visible5(val){
			if(val == false) this.cardRegInit = false;
		},
		visible2(val){
			if(val == false) this.authInquriy;
		},
		pageIndex(){
			this.authInquriy();
		}
	},
	computed: {
		...mapState([
			'corpTypeList'
		])
	},
	methods: {
		selectCardCompany(){
			this.pageIndex = 10;
			this.pageInit = true;
			this.authInquriy();
		},
		getCardCompanyList(val){
			let that = this;

			let scrapSiteInfo = new Object();
			if(val != ''){
				scrapSiteInfo.bizKind = val;
			}

			this.$cAxios.post(this.prefix+"/finance/company/list/inquriy",JSON.stringify(scrapSiteInfo),{
				headers:{
					'Content-Type':'application/json'
				}
			})
			.then((res)=>{
				if(res.data != null){
					this.cardCompanyList = [];
					this.cardCompanyList.push({key:'',value:"전체"});
					for(var i = 0 ; i < res.data.length; i++){
						var resData = {
							key : (val == '' ? res.data[i].scrapSubCode : res.data[i].idx),
							value : res.data[i].name
						};
						this.cardCompanyList.push(resData);
					}

				}

			}).catch((err)=>{
				console.log(err.response);
			}).finally(()=>{
				that.authInquriy();
			});

		},
		authInquriy(){
			let that = this;
			let corpGbn = document.getElementById("selectBoxCorp");
			this.corpGbn = corpGbn.options[corpGbn.selectedIndex].value;

			let cardGbn =  document.getElementById("selectBoxCard");
			this.cardGbn = cardGbn.options[cardGbn.selectedIndex].value;

			let param = new Object();
			if(corpGbn.selectedIndex != 0){
				param.bizKind = this.corpGbn; // 개인법인 구분
			}

			if(corpGbn.selectedIndex > 0 ){
				param.idx = this.cardGbn; // scrapSite idx
			} else if( corpGbn.selectedIndex == 0){
				param.scrapSubCode = this.cardGbn; // scrapSite scrapSubCode
			}
			param.limit = this.pageLimit;
			param.offset = (this.pageIndex - this.pageLimit);

			this.$cAxios.post(this.prefix+"/auth/list/inquriy/paging", JSON.stringify(param),{
				headers:{
					'Content-type':'application/json;'
				}
			})
			.then((res)=>{
				if(res.data == null){
					return false;
				}
				
				if(res.data.length == 0){
					this.authCount = 0;
					that.$set(that.tableData_attr, "items", []);
					return false;
				}

				this.authCount = res.data[0].authCnt;

				let tableList = new Array();
				for(var i = 0; i < res.data.length; i++ ){

					let resData = {
						idx:res.data[i].idx,
						scrapSiteName: res.data[i].scrapSiteName,
						corpGbn: (res.data[i].bizKind == "PERSON" ? "개인" : "법인" ),
						authType: res.data[i].authType,
						authId: (res.data[i].authType == "ID"? res.data[i].authId : res.data[i].certNm),
						regDt:res.data[i].regDt.substring(0,10),
						status:res.data[i].status,
						statusDesc: res.data[i].statusDesc,
						financeCnt: res.data[i].financeCnt,
					}
					tableList.push(resData);
				}

				that.$set(that.tableData_attr, "items", tableList);
			})
			.catch(()=>{

			})
			.finally(()=>{
				this.delRequestAuthList = [];
				this.pageInit = false;
			});
		},
		deleteAuthList(){
			let authList = this.delRequestAuthList;
			if(!authList.length > 0) {
				alert("삭제할 인증정보를 선택해주세요.");
				return false;
			}

			if(!confirm("삭제하시겠습니까?")) return false;

			let paramList = new Array();
			for(var i = 0 ; i< authList.length; i++){
				let param = {};
				param.idx = authList[i];
				paramList.push(param);
			}

			this.$cAxios.post(this.prefix+"/auth/delete",JSON.stringify(paramList),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then(()=>{
				alert("삭제되었습니다.");
				this.authInquriy();
			}).catch((err)=>{
				if(err.response){
					alert("인증정보 삭제에 문제가 발생했습니다. 다시 시도해주세요.");
				}
			}).finally(()=>{
				this.delRequestAuthList = [];
			});
		},
		openLoginIdPwdPopup() {
			this.visible1 = true;
		},
		openCreditCardMngPopup(item) {
			let authIdx = item.idx.toString();
			let param = {};
			param.idx = authIdx;
			this.$cAxios.post(this.prefix+"/card/list/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.cardInfoTable_attrs.idx = authIdx;
					this.cardInfoTable_attrs.cardNm = res.data.scrapSiteName;
					this.cardInfoTable_attrs.authType = res.data.authType;
					this.cardInfoTable_attrs.authNm = (res.data.authType == 'CERT'? res.data.certNm: res.data.authId);
					this.cardInfoTable_attrs.bizKind = res.data.bizKind;

					let cardList = new Array();
					let inquriyCardList = res.data.scrapFinances;
					if(!inquriyCardList.length){
						let cardInfo = {
							idx : "",
							cardNo : "",
							cardAlias : "",
							status : "",
							statusDesc : ""
						};
						cardList.push(cardInfo);
						this.$set(this.cardInfoTable_attrs,'bodys',cardList);
						return;
					}

					for(var i = 0; i < inquriyCardList.length; i++){
						let cardInfo = {};
						cardInfo.idx = inquriyCardList[i].idx.toString();
						cardInfo.cardNo = inquriyCardList[i].financeNo;
						cardInfo.cardAlias = inquriyCardList[i].financeAlias;
						cardInfo.status = inquriyCardList[i].status;
						cardInfo.statusDesc = inquriyCardList[i].statusDesc;
						cardList.push(cardInfo);
					}
					this.$set(this.cardInfoTable_attrs,'bodys',cardList);
				}
			}).finally(()=>{
				this.visible2 = true;
			});

		},
		openLoginDetailsInfoPopup_cert(val) {
			let that = this;

			let param = new Object();
			param.idx = val;
			param.authType = 'CERT';
			this.$cAxios.post(this.prefix+"/auth/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.loginDetailsInfo_cert_attrs.scrapSiteType = "CARD";
					this.loginDetailsInfo_cert_attrs.scrapSiteName = res.data.scrapSiteName;
					this.loginDetailsInfo_cert_attrs.authType = res.data.authType;
					this.loginDetailsInfo_cert_attrs.bizKind = res.data.bizKind;

					let resData = [
						{th: '인증서 ID', td:res.data.certNm},
						{th: '등록기관', td:res.data.certOrgNm},
						{th: '인증서 유효기간', td:res.data.validDateTerm},
						{th: '상태', td:(res.data.status == 'VALID'? '정상':'오류')},
					]
					that.$set(that.loginDetailsInfo_cert_attrs,"bodys",resData);
					that.loginDetailsInfo_cert_attrs.idx = val.toString();
				}
			}).catch(()=>{

			}).finally(()=>{
				this.visible3 = true;
			});

		},
		openLoginDetailsInfoPopup_idpwd(val) {
			let that = this;

			let param = new Object();
			param.idx = val;
			param.authType = "ID";
			this.$cAxios.post(this.prefix+"/auth/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type': 'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.loginDetailsInfo_idpwd_attrs.scrapSiteType = "CARD";
					this.loginDetailsInfo_idpwd_attrs.scrapSiteName = res.data.scrapSiteName;
					this.loginDetailsInfo_idpwd_attrs.authType = res.data.authType;
					this.loginDetailsInfo_idpwd_attrs.bizKind = res.data.bizKind;

					that.loginDetailsInfo_idpwd_attrs.bodys = [];

					let resData = [
						{th: '웹 아이디', td:res.data.authId},
						{th: '웹 패스워드', td:'******'},
						{th: '인증상태', td:(res.data.status == 'VALID'? '정상':'오류')},
					]
					that.$set(that.loginDetailsInfo_idpwd_attrs, "bodys", resData);
					that.loginDetailsInfo_idpwd_attrs.idx = val.toString();
				}
			}).catch(()=>{

			}).finally(()=>{
				this.visible4 = true;
			});


		},
		pwdChange(){
			if(this.pwdChangeYn) return false;
			this.pwdChangeYn = !this.pwdChangeYn;
		},
		pwdUpdate(){
			let options = {
				updateYN:true,
				type:'ID'
			};
			this.executeUpdateOptions = options;
		},
		executePwdUpdate(idx, pwd){
			let param = new Object();
			param.idx = idx;
			param.password = pwd;

			this.$cAxios.post(this.prefix+"/idpw/replace",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then(()=>{
				alert("변경되었습니다.");
				this.executeUpdateOptions = {};
				this.visible4 = false;
				this.pwdChangeYn = false;
				this.authInquriy();
			}).catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
			});
		},
		certUpdate(){
			this.executeUpdateOptions = {
				updateYN:true,
				type:'CERT'
			}
		},
		executeCertUpdate(idx){
			this.loadDoc('setup', null, idx.toString());
		},
		loadDoc (s_op, s_inJson, authIdx) {

			let loadNxClient = (v_s_op, v_s_inJson) => {

				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}

				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							fnNxDownload();
						}
					}

				}).finally(() => {
				});

			}

			//첫 로드
			loadNxClient(s_op, s_inJson);
			var sendData = new Object();
			let result = (s_op, data) => {
				var errYn = data.errYn;

				if('setup' == s_op){
					if (errYn == 'N') {
						//certImageUrl : 배너이미지
						loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}');
					}
				} else if ('certSelect' == s_op) {
					//인증서 선택
					sendData.certNm = data.cert_nm; //인증서명
					sendData.certPubDate = data.pub_dt;
					sendData.certEndDate = data.end_dt;
					sendData.certOrgNm = data.org_nm;//인증기관명 (yessign 등...)
					sendData.certPolicyId = data.oid;
					sendData.certSerialNo = data.sn;
					sendData.password = data.cert_pw;

					callExecute(data);
				} else if ('execute' == s_op) {
					if (errYn == 'N') {
						sendData.signCert = data.DER2PEM;
						sendData.signPri = data.KEY2PEM;
						sendData.idx = authIdx;
						sendCertData(JSON.stringify(sendData));
					}
				}


			}


			let callExecute = (data) => {
				var inJson = new Object();
				inJson.orgCd = 'common';
				inJson.svcCd = 'getCertInfo';
				inJson.appCd = 'ant';
				inJson.signCert = data.file1;
				inJson.signPri = data.file2;
				inJson.signPw = data.cert_pw;

				loadNxClient('execute', JSON.stringify(inJson));
			}

			let sendCertData = (sendData) => {
				this.$cAxios.post(this.prefix + "/cert/replace", sendData,{
					headers : {
						'Content-Type':'application/json'
					}
				}).then(()=>{
					alert("변경되었습니다.");
					this.visible3 = false;
					this.authInquriy();
				}).catch((err)=>{
					if(err.response){
						alert(err.response.data.message);
					}
				});

			}

			let fnNxDownload = () => {
				location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
			}


		},
		openSimpleCardRegPopup() {
			this.cardRegInit = true;
			this.visible5 = true;
		},
		closeCreditCardRegPopup(val){
			this.visible2 = val;
			this.authInquriy();
		},
		closeSimpleCardRegPopup(val){
			this.visible5 = val;
			this.authInquriy();
		}
	},
	mounted: function(){
		this.getCardCompanyList('');
	}
}
</script>
